<template>
  <div class="feedback has-primary-background wrapper-full">
    <img class="feedback__image" v-if="dataset.img" :src="dataset.img">
    <h1 class="feedback__title" v-if="dataset.title" v-html="dataset.title"></h1>
    <p v-if="dataset.bibel" class="feedback__bibel-verse">{{dataset.bibel.verse}}</p>
    <p v-if="dataset.bibel" class="feedback__bibel-text" v-html="dataset.bibel.text"></p>
    <BigButton v-if="buttonData" :direction="buttonData.direction" :link="buttonData.link">{{buttonData.text}}</BigButton>
  </div>
</template>

<script>
import BigButton from "@/components/BigButton.vue";
import store from "../store";

export default {
  name: "Feedback",
  components: {
    BigButton,
  },
  data() {
    return {
      data: {
        // Q1
        "q1-wrong": {
          "title": "Versuch's nochmal!",
          "button": {
            "text": "Zurück zu Situation 1",
            "link": "/question-one",
            "direction": "back"
          }
        },
        "q1-correct": {
          "title": "RICHTIG!",
          "button": {
            "text": "Weiter zu Situation 2",
            "link": "/question-two",
            "direction": "forward"
          }
        },
        // Q2
        "q2-lost-son": {
          "img": require("../assets/images/image-lost-son.svg"),
          "title": "Wenn der Vater auf diesen Sohn hört, stellt er den Heimkehrer als Diener oder Knecht ein.<br> Er kann bleiben, aber es wird nicht mehr so wie früher.",
          "button": {
            "text": "Zurück zu Situation 2",
            "link": "/question-two",
            "direction": "back"
          }
        },
        "q2-father": {
          "img": require("../assets/images/image-father.svg"),
          "title": "RICHTIG!",
          "button": {
            "text": "Weiter zu Frage 3",
            "link": "/question-three",
            "direction": "forward"
          }
        },
        "q2-second-son": {
          "img": require("../assets/images/image-second-son.svg"),
          "title": "Wenn der Vater auf diesen Sohn hört, schickt er den Heimkehrer wieder weg.",
          "button": {
            "text": "Zurück zu Situation 2",
            "link": "/question-two",
            "direction": "back"
          }
        },
        // Q3
        "q3-roll-01": {
          "img": require("../assets/images/image-roll-01.svg"),
          "bibel": {
            "verse": "5. Mose 6,4-5",
            "text": "Höre, Israel: Der Herr ist unser Gott, der Herr allein! Du sollst den Herrn, deinen Gott, lieben mit deinem ganzen Herzen, mit deiner ganzen Seele und mit deiner ganzen Kraft."
          },
          "button": {
            "text": "Zurück zu Frage 3",
            "link": "/question-three",
            "direction": "back"
          }
        },
        "q3-roll-02": {
          "img": require("../assets/images/image-roll-02.svg"),
          "bibel": {
            "verse": "3. Mose 19,18",
            "text": "Du sollst dich nicht rächen und deinen Brüdern und Schwestern nichts nachtragen. Stattdessen sollst du deinen Mitmenschen lieben wie dich selbst."
          },
          "button": {
            "text": "Zurück zu Frage 3",
            "link": "/question-three",
            "direction": "back"
          }
        },
        "q3-roll-03": {
          "bibel": {
            "verse": "Markus 12,29-31",
            "text": "Jesus sagte:<br>Das wichtigste Gebot ist dieses:<br>Höre, Israel: Der Herr ist unser Gott,<br>der Herr allein! Du sollst den Herrn, deinen Gott, lieben mit deinem ganzen Herzen, mit deiner ganzen Seele, mit deinem ganzen Denken und mit deiner ganzen Kraft.<br><span class='spacer'></span>Und als Zweites kommt dieses dazu: Liebe deinen Mitmenschen wie dich selbst.<br><span class='spacer'></span>Kein anderes Gebot ist wichtiger als diese beiden."
          },
          "button": {
            "text": "Zurück zu Frage 3",
            "link": "/question-three",
            "direction": "back"
          }
        }
      }
    }
  },

  computed: {
    dataset() {
      return this.data[this.$route.params.id];
    },
    buttonData() {

      // Nicht zu Frage 3: default
      if (this.$route.params.id.indexOf('q3-') == -1) {
        return this.data[this.$route.params.id].button;
      } 

      // Noch nicht alle 3 Texte gesehen: default
      if (this.q3count.length !== 3) {
        return this.data[this.$route.params.id].button;
      }

      // weiter zum Ende
      return {
        "text": "Weiter",
        "link": "/ending",
        "direction": "forward"
      }
    },
    q3count () {
      return store.state.answersCountQuestionThree
    }
  },
  beforeMount() {
    
    /**
     * add ID to array for question 3
     */
    if (this.$route.params.id.indexOf('q3-') !== -1) {
      if (!this.q3count.includes(this.$route.params.id)) {
        store.commit('addIdToAnswersCountQuestionThree', this.$route.params.id)
      }
    } 
  },
};
</script>


<style lang="scss">
  .feedback {
    &__image {
      margin-bottom: 7vw;
      width: 28vw;
      height: 28vw;
      border-radius: 50%;
      background-color: var(--color--light);
    }

    &__title {
      max-width: 80vw;
      font-size: 4.8vw;
      font-family: 'Raleway';
      font-weight: 700;
      margin-bottom: 7vw;
    }

    &__bibel-verse {
      color: var(--color--light);
      font-size: 2.8vw;
      font-weight: 700;
      padding: 2vw 5vw;
      border-bottom: 1px solid var(--color--light);
      margin-top: -4vw;
    }

    &__bibel-text {
      color: var(--color--light);
      font-style: italic;
      text-align: center;
      font-size: 4vw;
      max-width: 80vw;
      margin-top: 0;
      line-height: 1.4;
      margin-bottom: 5vw;

      .spacer {
        height: 3vw;
        display: block;
      }
    }
  }
</style>