<template>
  <div class="ending has-primary-background wrapper-full">
    <h1>Herzlichen<br>Glückwunsch!<br>Gewonnen!</h1>
    <h2>Oder wie Jesus sagen würde:<br>Geh hin und mach es genau so!</h2>
    <img class="feedback__image" src="../assets/images/image-heart.svg">
    <div class="ending__arrow-down"></div>
    <p>Wenn Du dieses Herz mit Deinem Handy abfotografierst<br>und unten im Shop vorzeigst, bekommst Du eine kleine<br>Überraschung. Oder, wenn Du kein Handy dabei hast,<br>versuch’s mit dem Codewort <strong>„Nächstenliebe“!</strong></p>
  </div>
</template>

<style scoped lang="scss">
  .ending {
    &__arrow-down {
      width: 2.5vw;
      height: 2vw;
      background-color: var(--color--light);
      margin-top: 4vw;
      margin-bottom: 0vw;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
    }
  }

  h1 {
    margin-top: 0;
  }

  h2 {
    text-align: center;
    margin-top: 3vw;
    margin-bottom: 4vw;
  }

  p {
    color: var(--color--light);
    text-align: center;
    line-height: 1.3;
  }
</style>