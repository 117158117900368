<template>
  <router-link class="big-button" :data-direction="direction" :to="link" @click.native="setTransition">
    <div ref="inner" class="big-button__inner">
      <p class="big-button__text"><slot></slot></p>
      <svg class="big-button__icon" xmlns="http://www.w3.org/2000/svg" width="190.11" height="63.42" viewBox="0 0 190.11 63.42">
        <path  d="M0,0V63.42L54.922,31.729ZM-67.594,0V63.42l54.922-31.691Zm-67.594,0V63.42l54.921-31.691Z" transform="translate(135.188)" fill="#ff8d2a"/>
      </svg>
    </div>
  </router-link>
</template>

<script>
import { gsap } from 'gsap';
import store from "../store";

export default {
  name: "BigButton",
  props: {
    link: String,
    direction: String
  },
  methods: {
    setTransition() {
      if( this.direction && this.direction == "back") {
        store.commit("setPageTransitionDirection", "backwards")
      }
    }
  },
  mounted() {
    const { inner } = this.$refs;

    gsap.timeline({repeat: -1})
      .to(this.$el, {scaleX: 1.02, scaleY: 1.02, boxShadow: "0.5vw 0.5vw 1vw rgba(0,0,0,0.5)", duration: 1}, 0)
      .to(this.$el, {scaleX: 1, scaleY: 1, boxShadow: "0.3vw 0.3vw 0vw rgba(0,0,0,0.5)", duration: 1}, 1);
  }
};
</script>

<style lang="scss">

  $margin: 2vw;

  .big-button {
    border: 0.4vw solid var(--color--light);
    border-radius: 50%;
    height: 30vw;
    width: 30vw;
    color: var(--color--primary);
    text-decoration: none;
    box-shadow: 0.3vw 0.3vw 0 rgba(0,0,0,0.5);

    &__inner {
      overflow: hidden;
      width: calc(100% - #{$margin*2});
      height: calc(100% - #{$margin*2});    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: $margin solid var(--color--primary);
      background-color: var(--color--light);
      border-radius: 50%;
    }

    &__text {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 3.5vw;
      text-align: center;
      padding: 0 2vw;
      margin-bottom: 2vw;
    }

    &__icon {
      width: 10vw;
      height: auto;

      > path {
        fill: var(--color--primary);
      }
    }

    &[data-direction="back"] {
      .big-button {
        &__icon {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
