<template>
  <div class="question question-one wrapper-full">

    <h2 class="alt">Frage 3</h2>
    <h3 class="alt">Und jetzt testen wir noch, wie bibelfest Du bist!<br> Wo steht das Liebesgebot und was ist damit gemeint?</h3>
    <img class="arrow-down" src="@/assets/images/icon-arrow-down.svg">

    <div class="canvas">

      <!-- roll-01 -->
      <router-link class="canvas__roll-01" to="/feedback/q3-roll-01">
        <img src="@/assets/images/q3-roll-01.svg">
        <div class="pointer"></div>
        <p class="verse">5. Mose 6,4-5</p>
      </router-link>

      <!-- roll-02 -->
      <router-link class="canvas__roll-02" to="/feedback/q3-roll-02">
        <img src="@/assets/images/q3-roll-02.svg">
        <div class="pointer"></div>
        <p class="verse">3. Mose 19,18</p>
      </router-link>

      <!-- roll-03 -->
      <router-link class="canvas__roll-03" to="/feedback/q3-roll-03">
        <img src="@/assets/images/q3-roll-03.svg">
        <div class="pointer"></div>
        <p class="verse">Markus 12,29-31</p>
      </router-link>

    </div>

  </div>
</template>

<style scoped lang="scss">
  .canvas {
    width: 90vw;
    height: calc(100% - 45vw);
    position: relative;

    .verse {
      background-color: var(--color--primary);
      padding: 0.8vw 2.5vw;
      color: var(--color--light);
      font-weight: 700;
      font-size: 2.8vw;
    }

    &__roll-01 {
      top: 10vw;
      left: 5vw;

      >.verse {
        position: absolute;
        white-space: nowrap;
        right: -75%;
        top: 20%;
        z-index: 3;
      }

      >.pointer {
        width: 15vw;
        height: 15vw;
        top: 10vw;
        left: 8.5vw;
      }

      > img {
        width: 24vw;
        position: relative;
        z-index: 3;
      }
    }

    &__roll-02 {
      top: 25vw;
      right: 10vw;

      >.verse {
        position: absolute;
        white-space: nowrap;
        left: -65%;
        top: 10%;
        z-index: 3;
      }

      >.pointer {
        width: 15vw;
        height: 15vw;
        top: 13.5vw;
        left: 1.8vw;
      }

      > img {
        width: 20vw;
        position: relative;
        z-index: 3;
      }
    }

    &__roll-03 {
      bottom: 8vw;
      left: 10vw;

      >.verse {
        position: absolute;
        white-space: nowrap;
        left: 15%;
        top: 40%;
        z-index: 3;
      }

      >.pointer {
        width: 15vw;
        height: 15vw;
        top: 9vw;
        right: -1vw;
      }

      > img {
        width: 40vw;
        position: relative;
        z-index: 3;
      }
    }
  }

</style>
