import { createStore } from "vuex";
import router from "../router";

export default createStore({
  state: {
    answersCountQuestionThree: [],
    pageTransitionDirection: "",
    lastActiveTime: new Date().getTime(),
    idelChecker: false,
    secondsOfIdleToReset: 300 // 5 Minuten
  },
  mutations: {
    addIdToAnswersCountQuestionThree(state, id) {
      state.answersCountQuestionThree.push(id)
    },
    resetAnswersCountQuestionThree(state) {
      state.answersCountQuestionThree = [];
    },
    setLastActiveTime(state) {
      state.lastActiveTime = new Date().getTime();
    },
    setPageTransitionDirection(state, direction) {
      state.pageTransitionDirection = direction;
    },
  },
  actions: {
    resetApp({ commit }) {
      commit("resetAnswersCountQuestionThree");
      router.push("/");
    },
    setLastActiveTime({commit}) {
      commit('setLastActiveTime')
    },
    startIdelChecker({state, commit, dispatch}) {
      window.idelChecker = setInterval(function(){ 
        const secondsSinceLastactive = Math.round((new Date().getTime() - state.lastActiveTime) / 1000);
        if (secondsSinceLastactive >= state.secondsOfIdleToReset) {
          dispatch("resetApp");
        }
      }, 1000);
    },
    stopIdelChecker({commit}) {
      clearInterval(window.idelChecker);
    },
  }
});
