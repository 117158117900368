<template>
  <div class="question question-one wrapper-full">

    <h2 class="alt">Situation 2</h2>
    <h3 class="alt">Ein junger Mann hat sich sein Erbe auszahlen lassen<br>und alles verprasst. Jetzt lebt er im Elend<br>und sehnt sich nach seinem Zuhause.<br>Wie kann es weitergehen?</h3>
    <p class="question__verse">(nach Lukas 15,11-32)</p>
    <img class="arrow-down" src="@/assets/images/icon-arrow-down.svg">

    <div class="canvas">

      <!-- lost son -->
      <router-link class="column canvas__lost-son" to="/feedback/q2-lost-son">
        <img src="@/assets/images/q2-lost-son.svg">
        <div class="pointer"></div>
        <img class="arrow-down" src="@/assets/images/icon-arrow-down.svg">
        <div class="text">
          <h3>Der Prasser,<br>der alles<br>verspielt hat</h3>
        </div>
      </router-link>

      <!-- father -->
      <router-link class="column canvas__father" to="/feedback/q2-father">
        <img src="@/assets/images/q2-father.svg">
        <div class="pointer"></div>
        <img class="arrow-down" src="@/assets/images/icon-arrow-down.svg">
        <div class="text">
          <h3>Der Vater,<br>der sich große<br>Sorgen macht</h3>
        </div>
      </router-link>

      <!-- second son -->
      <router-link class="column canvas__second-son" to="/feedback/q2-second-son">
        <img src="@/assets/images/q2-second-son.svg">
        <div class="pointer"></div>
        <img class="arrow-down" src="@/assets/images/icon-arrow-down.svg">
        <div class="text">
          <h3>Der Sohn,<br>der alles richtig<br>macht</h3>
        </div>
      </router-link>

    </div>

  </div>
</template>

<style scoped lang="scss">
  .canvas {
    width: 90vw;
    height: calc(100% - 50vw);
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 3vw;

    .column {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .arrow-down {
        width: 2.2vw;
        height: 2.2vw;
        margin-top: 1vw;
        margin-left: 2vw;
      }

      > img {
        width: 27vw;
        position: relative;
        z-index: 3;
      }

      >.pointer {
        width: 15vw;
        height: 7vw;
      }

      .text {
        text-align: center;
      }

      h3 {
        text-transform: none;
      }
    }

    &__lost-son {
      >.pointer {
        left: 6vw;
        top: 54vw;
      }
    }

    &__father {
      >.pointer {
        left: 5vw;
        top: 55vw;
      }
    }

    &__second-son {
      >.pointer {
        left: 4vw;
        top: 56vw;
      }
    }
  }

</style>
