<template>
  <div class="question question-one wrapper-full">

    <h2 class="alt">Situation 1</h2>
    <h3 class="alt">Einer liegt nach einem Überfall am Boden<br> und braucht Hilfe. Wer macht es richtig?</h3>
    <p class="question__verse">(nach Lukas 10,25-37)</p>
    <img class="arrow-down" src="@/assets/images/icon-arrow-down.svg">

    <div class="canvas">

      <!-- victim -->
      <img class="canvas__victim" src="@/assets/images/q1-victim.svg">

      <!-- priest -->
      <router-link class="canvas__priest" to="/feedback/q1-wrong">
        <div class="pointer"></div>
        <img src="@/assets/images/q1-priest.svg">
        <div class="text">
          <h3 class="arrow-before arrow-before--right">Der Priester</h3>
          <p>muss zum Tempeldienst und darf<br>sich dafür nicht verunreinigen.<br>Darum geht er lieber weiter.</p>
        </div>
      </router-link>

      <!-- levit -->
      <router-link class="canvas__levit" to="/feedback/q1-wrong">
        <img src="@/assets/images/q1-levit.svg">
        <div class="pointer"></div>
        <div class="text">
          <h3 class="arrow-after arrow-after--down">Der Levit</h3>
          <p>beeilt sich. Er muss<br> rechtzeitig zu seiner<br> Arbeit am Tempel<br> kommen.</p>
        </div>
      </router-link>

      <!-- eccentric -->
      <router-link class="canvas__eccentric" to="/feedback/q1-correct">
        <div class="text">
          <h3 class="arrow-after arrow-after--left">Der Sonderling</h3>
          <p>gehört zur Religionsgemeinschaft<br> der Samaritaner, die mit Juden<br> wie dem Überfallenen eigentlich<br> nichts zu tun haben wollen,<br> hält aber an.</p>
        </div>
        <img src="@/assets/images/q1-eccentric.svg">
        <div class="pointer"></div>
      </router-link>

    </div>

  </div>
</template>

<style scoped lang="scss">
  .canvas {
    width: 90vw;
    height: calc(100% - 45vw);
    position: relative;

    &__victim {
      width: 40vw;
      height: 25.3vw;
      left: calc(50% - 20vw);
    }

    &__priest {
      top: 18vw;
      display: flex;
      align-items: center;

      >.text {
        margin-left: 3vw;
        margin-top: 8vw;
      }

      >.pointer {
        width: 15vw;
        height: 7vw;
        bottom: -2vw;
      }

      > img {
        width: 13vw;
        height: 36.2vw;
        position: relative;
        z-index: 3;
      }
    }

    &__levit {
      top: 9vw;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      >.pointer {
        width: 15vw;
        height: 7vw;
        top: 30vw;
      }

      > img {
        width: 12vw;
        height: 35.1vw;
        position: relative;
        z-index: 3;
      }
    }

    &__eccentric {
      bottom: 0vw;
      right: 28vw;
      display: flex;
      align-items: center;

      >.text {
        text-align: right;
        margin-right: 3vw;
      }

      >.pointer {
        width: 15vw;
        height: 7vw;
        top: 29vw;
        right: -1vw;
      }

      > img {
        width: 14vw;
        height: 33.3vw;
        position: relative;
        z-index: 3;
      }
    }
  }




</style>
