import { createRouter, createWebHashHistory } from "vue-router";
import store from '../store';
import Start from "../views/Start.vue";
import QuestionOne from "../views/QuestionOne.vue";
import QuestionTwo from "../views/QuestionTwo.vue";
import QuestionThree from "../views/QuestionThree.vue";
import Feedback from "../views/Feedback.vue";
import Ending from "../views/Ending.vue";

const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
  },
  {
    path: "/question-one",
    name: "QuestionOne",
    component: QuestionOne,
  },
  {
    path: "/question-two",
    name: "QuestionTwo",
    component: QuestionTwo,
  },
  {
    path: "/question-three",
    name: "QuestionThree",
    component: QuestionThree,
  },
  {
    path: "/feedback/:id",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/ending",
    name: "Ending",
    component: Ending,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to, from) => {
  store.dispatch("setLastActiveTime");

  // initial
  if ( !(from.path == "/" && to.path == "/") ) {


    // Start interval
    if (from.path == "/") {
      store.dispatch("startIdelChecker");
    }

    // Stop interval
    if (to.path == "/") {
      store.dispatch("stopIdelChecker");
    }
  }

})

export default router;
