<template>
  <div class="restart" v-if="$route.name !== 'Start'" @click="askForRestart">
    <img src="../assets/images/icon-restart.svg">
  </div>
</template>

<script>
  import store from "../store";

  export default {
    name: "RestartButton",
    methods: {
      askForRestart() {
        const restart = confirm("Wollen Sie noch einmal von vorne beginnen?");
        if (restart == true) {
          store.commit("setPageTransitionDirection", "backwards")
          store.dispatch('resetApp');
        } 
      }
    }
  };
</script>

<style lang="scss">
  .restart {
    position: absolute;
    right: 2.5vw;
    top: 2.5vw;
    border-radius: 50%;
    padding: 0.7vw;
    background: rgba(0,0,0,0.1);
    width: 5vw;
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    img {
      width: 90%;
    }
  }
</style>
