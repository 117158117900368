<template>
  <div class="home has-primary-background wrapper-full">
    <h3>Das wichtigste Gebot:</h3>
    <h1>Gott und<br>die Menschen<br>LIEBEN!</h1>
    <h2>Weißt Du, wie's geht?</h2>
    <BigButton link="/question-one" >Hier geht's<br> zum Quiz</BigButton>
  </div>
</template>

<script>
  import BigButton from "@/components/BigButton.vue";

  export default {
    name: "Start",
    components: {
      BigButton,
    },
  };
</script>