<template>
  <router-view v-slot="{ Component }">
    <RestartButton />
    <transition @enter="enterTransition" @leave="leaveTransition" mode="in-out" :css="false">
      <component :is="Component"/>
    </transition> 
  </router-view>
</template>

<script>
  import RestartButton from "@/components/RestartButton.vue";
  import store from './store';
  import gsap from 'gsap';

  export default {
   components: {
     RestartButton,
   },
   computed: {
    pageTransitionDirection() {
      return store.state.pageTransitionDirection;
    }
   },
   methods: {
    enterTransition(el, done) {
      done()

      if (this.pageTransitionDirection == 'forwards') {
        gsap.timeline()
          .set(el, {position: "absolute", top: 0, "z-index": 9})
          .fromTo(
            el, 
            {
              alpha: 0,
              duration: 0.5
            },
            {
              alpha: 1,
              duration: 0.5
            },
            0
          )
          .fromTo(
            el, 
            {
              scaleX: 1.1,
              scaleY: 1.1,
              duration: 0.5
            },
            {
              scaleX: 1,
              scaleY: 1,
              duration: 0.5
            },
            0
          );

      } else if (this.pageTransitionDirection == 'backwards') {
        store.commit("setPageTransitionDirection", "forwards")
        gsap.timeline()
          .from(
            el, 
            {
              scaleX: 0.8,
              scaleY: 0.8,
              alpha: 0,
              duration: 0.5,
            }
          );

      } else {
        done();
      }
    },
    leaveTransition(el, done) {
      console.log('leave');

      if (this.pageTransitionDirection == 'forwards') {
        gsap.timeline()
          .to(
            el, 
            {
              scaleX: 0.8,
              scaleY: 0.8,
              alpha: 0,
              duration: 0.5,
            }
          )
          .call(done);

      } else if (this.pageTransitionDirection == 'backwards') {
        gsap.timeline()
          .set(el, {position: "absolute", top: 0, "z-index": 9})
          .to(
            el, 
            {
              scaleX: 1.1,
              scaleY: 1.1,
              alpha: 0,
              duration: 0.5
            },
          )
          .call(done);

      } else {
        done();
      }
    }
   },
   mounted() {
    setTimeout(function() {
      store.commit("setPageTransitionDirection", "forwards")
    }, 100);
   }
  };
</script>

<style lang="scss">
  @import '@/assets/scss/global.scss';
  @import '@/assets/scss/questions.scss';
</style>
